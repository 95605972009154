<template>
  <div>
    <vue-html2pdf
      :preview-modal="true"
      :enable-download="true"
      manual-pagination="true"
      pdf-content-width="1200px"
      :pdf-quality="2"
      filename="QR_Code"
      pdf-format="a3"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900"
        />
        <div
          :style="`
            background: url('${template.var.qrcodeplakat}');
            background-size: cover;
            background-position: bottom center;
            width: 1110px;
            height: 1576px;
            margin-top: 10px;
            padding-top: 120px;
            padding-left: 120px;
            padding-right: 120px;
          `"
        >
          <div
            style="
              font-family: Roboto;
              color: #000000;
              text-align: center;
              width: 850px;
              padding-left: 140px;
              padding-right: 140px;
              padding-top: 50px;
              padding-bottom: 50px;
            "
          >
            <h1
              style="
                font-size: 30px;
                align: center;
                font-weight: 700;
                margin-bottom: 50px;
              "
            >
              QR-Code einscannen um sich mit dem mobilen Anmeldeformular zu
              registrieren
            </h1>
            <qrcode-vue
              level="Q"
              :value="qrlink + '' + $route.params.alias"
              size="570"
            ></qrcode-vue>
            <h1
              style="
                font-size: 20px;
                align: center;
                font-weight: 700;
                margin-top: 50px;
              "
            >
              https://flvw.app/{{ $route.params.alias }}
            </h1>
          </div>
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import VueHtml2pdf from 'vue-html2pdf'
import { mapGetters } from 'vuex'

export default {
  name: 'PDF-Generator',
  data() {
    return {
      qrlink: 'https://flvw.app/',
    }
  },
  components: {
    QrcodeVue,
    VueHtml2pdf,
  },
  computed: {
    ...mapGetters({
      user: 'user',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
  },
  mounted() {
    this.generateReport()
  },
  methods: {
    /*
            Generate Report using refs and calling the
            refs function generatePdf()
        */
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
      this.$emit('generated', true)
    },
  },
}
</script>
>
